<template>
  <div
    v-if="eventData"
    class="d-flex flex-column align-center justify-center"
    style="height: 100vh"
    :dir="eventData.lang === 'he' || eventData.lang === 'he' ? 'rtl' : 'ltr'"
  >
    <v-card
      class="mx-auto"
      max-width="400"
      width="100%"
      color="transparent"
      flat
    >
      <!-- Button to navigation by Waze -->
      <v-btn
        block
        x-large
        class="mb-7 py-9 px-4 rounded-xl"
        style="width: 300px; display: flex; align-items: center; justify-content: start;"
        :href="`https://waze.com/ul?ll=${eventData.location.latitude},${eventData.location.longitude}&navigate=yes`"
      >
        <img
          :src="require('./icons/Waze.png')"
          alt="Waze"
          style="height: 50px; width: 50px;"
        />
        <div class="px-2 text-xl">
          {{ $t('NavigationByWaze', eventData.lang) }}
        </div>
      </v-btn>
      <!-- Button to navigation by google maps -->
      <v-btn
        block
        x-large
        class="rounded-xl px-4 py-9"
        style="width: 300px; display: flex; align-items: center; justify-content: start;"
        :href="`https://www.google.com/maps/dir/?api=1&destination=${eventData.location.latitude},${eventData.location.longitude}&travelmode=driving`"
      >
        <img
          :src="require('./icons/GoogleMaps.png')"
          alt="Google Maps"
          style="height: 50px; width: 50px;"
        />
        <div class="px-2 text-xl">
          {{ $t('NavigationByGoogleMaps', eventData.lang) }}
        </div>
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import { useRouter } from '@core/utils'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  props: ['preview'],
  setup(props) {
    const { route } = useRouter()
    const eventData = ref(null)
    const loading = ref(false)

    const getEventDetails = () => {
      loading.value = true

      if (route.value.params.guestID && !props.preview) {
        store
          .dispatch('getInvite', { guestID: route.value.params.guestID })
          .then(response => {
            eventData.value = response.data
          })
          .catch(() => router.push({ name: 'error-404' }))
          .finally(() => {
            loading.value = false
          })
      } else {
        eventData.value = route.value.query
        loading.value = false
      }
    }

    getEventDetails()

    return {
      loading,
      eventData,
    }
  },
}
</script>
